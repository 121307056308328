import {initializeApp} from "@firebase/app";
import {getDatabase, onValue, ref} from "@firebase/database";
import {useInterval} from "usehooks-ts";
import {useEffect, useState} from "react";
import MockDate from 'mockdate';
import Clock from "react-clock";
import 'react-clock/dist/Clock.css';
import watercolor from "./watercolor.jpg";
import chroma from "chroma-js";
import './App.css';
import {Calendar, DayOfWeek} from '@uifabric/date-time';
import { initializeIcons } from '@uifabric/icons';
import { MdOutlineFastfood } from "react-icons/md";
import { CgShapeRhombus } from "react-icons/cg";

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();


const firebaseConfig = {
  apiKey: "AIzaSyDglqZ4pBpb_Z0_QxP3CbARCwduROZUu1A",
  authDomain: "geschichte-companion.firebaseapp.com",
  databaseURL: "https://geschichte-companion-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "geschichte-companion",
  storageBucket: "geschichte-companion.appspot.com",
  messagingSenderId: "29100930440",
  appId: "1:29100930440:web:f76cc027cab0cfe50cf63d",
  measurementId: "G-ZQNE5PSJY7"
};

export const mainGameRefStr = 'solarflare';
export const mainInvRefStr = 'solarflare/inventories/main';

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const timeRef = ref(database, mainGameRefStr + "/time");
const calorieDeficitRef = ref(database, mainGameRefStr + "/calorieDeficit");
const mainInvRef = ref(database, mainInvRefStr);

let realTime = 0;

onValue(timeRef, snapshot => {
  realTime = snapshot.val();
});

let smoothedDate = new Date(0);
let lastTimer = 0;

type Item = { count: number };

export default function App() {
  let [smoothedTime, setSmoothedTime] = useState(0);
  let [visions, setVisions] = useState(-1);
  let [calorieDeficit, setCalorieDeficit] = useState(0);
  let [smoothedCalorieDeficit, setSmoothedCalorieDeficit] = useState(0);
  let [needsInit, setNeedsInit] = useState(true);
  let [inventory, setInventory] = useState(new Map<string, Map<string, Item>>());
  const timeofday = ((smoothedDate.getHours() * 60 * 60) + (smoothedDate.getMinutes() * 60) + smoothedDate.getSeconds()) / (60 * 60 * 24);
  const bgColor = `linear-gradient(${colormap_top(timeofday)}, ${colormap_bottom(timeofday)})`;

  const fuzzedVis = (() => {
    let x = Date.now() / 86400000.0;
    return Math.floor(visions * (
      2
      + 0.01 * (Math.sin(10     * Math.PI * x + 1516) + Math.sin(20      * Math.E * x + 2841) + Math.sin(90      * x + 3823))
      + 0.1  * (Math.sin( 3     * Math.PI * x +    1) + Math.sin( 2      * Math.E * x +    2) + Math.sin( 3      * x +    3))
      + 0.2  * (Math.sin((1/7)  * Math.PI * x +    8) + Math.sin((2/7)   * Math.E * x +    4) + Math.sin((9/7)   * x +    7))
      + 0.4  * (Math.sin((1/30) * Math.PI * x +    9) + Math.sin((2/30)  * Math.E * x +    5) + Math.sin((9/30)  * x +   13))
    ) * 0.5
    );
  })();

  useEffect(() => {
    if(needsInit) {
      onValue(calorieDeficitRef, snapshot => {
        setCalorieDeficit(snapshot.val());
      });

      onValue(ref(database, mainGameRefStr + "/visionsLeft"), snapshot => {
        setVisions(snapshot.val());
      });

      onValue(mainInvRef, snapshot => {
        let val = snapshot.val();
        if(val !== null && val !== undefined && val instanceof Object) {
          let entries = Object.entries(val);
          let categories = new Map<string, Map<string, Item>>();
          entries.forEach(([name, data]) => {
            let _itemCategories = (data as any).categories;
            if(_itemCategories !== undefined) {
              let itemCategories = Object.entries(_itemCategories);
              itemCategories.forEach(([category, _count]) => {
                let count = _count as number;
                if(!isNaN(count) && count > 0) {
                  if(!categories.has(category)) {
                    categories.set(category, new Map());
                  }
                  categories.get(category)?.set(name, { count: count });
                }
              });
            }
          });
          setInventory(categories);
        } else {
          setInventory(new Map<string, Map<string, Item>>());
        }
      });

      setNeedsInit(false);
    }
  })

  useInterval(() => {
    if(lastTimer === 0) {
        lastTimer = performance.now();
    } else {
        let now = performance.now();
        if(now - lastTimer > 1000) {
            window.location.reload();
        }
        lastTimer = now;
    }
    if(realTime !== 0) {
      let newTime = 0;
      if(smoothedTime !== 0) {
        newTime = smoothedTime * 0.9 + realTime * 0.1;
        setSmoothedTime(newTime);
      } else {
        newTime = realTime;
        setSmoothedTime(newTime);
      }
      smoothedDate.setTime(newTime);
      MockDate.set(newTime);
    }
    if(Math.abs(smoothedCalorieDeficit - calorieDeficit) > 10) {
      setSmoothedCalorieDeficit((prev) => prev * 0.9 + calorieDeficit * 0.1);
    }
  }, 20);

  if(smoothedTime !== 0) {
    return (
        <div className={"scrollbar-hide h-[100vh] w-[100vw]"}>
          <div className={"fixed bottom-0 top-0 left-0 right-0 bg-fixed bg-cover -z-50"} style={{background: bgColor}}>
            <div className={"w-full h-full"} style={{backgroundImage: `url(${watercolor})`, mixBlendMode: 'soft-light'}}></div>
          </div>
          <div className={"flex flex-col m-5 items-center"}>
            <div className={"grid h-full place-items-center bg-white w-[310px] h-[310px] rounded-full m-3"}>
              <Clock value={smoothedDate} renderNumbers={true} size={300} renderSecondHand={false} />
            </div>

            <div className={"grid m-3 place-items-center rounded-2xl overflow-hidden bg-white -mt-20 w-[230px]"}>
              <p className={"mt-20 mb-2 text-2xl"}>
                {`${smoothedDate.getHours().toString().padStart(2, "0")}:${smoothedDate.getMinutes().toString().padStart(2, "0")}`}
              </p>
              <Calendar
                className={"-mb-2"}
                today={smoothedDate}
                showMonthPickerAsOverlay
                showGoToToday={false}
                firstDayOfWeek={DayOfWeek.Monday}
                showWeekNumbers={false}
                showSixWeeksByDefault={true}
                strings={cal_strings}
              />
            </div>

            <div className={"grid m-3 p-3 pb-4 place-items-center rounded-2xl overflow-hidden bg-white w-[230px] space-y-2"}>
              <h1 className={"text-2xl mb-3"}>Gesundheit</h1>
              <div className={"flex flex-row w-full justify-center mx-3 h-[32px]"}>
                <div className={"relative flex-grow bg-gray-300 w-full rounded-xl ml-1 overflow-hidden"}>
                  <div className={"absolute h-full left-2 grid place-items-center"}><MdOutlineFastfood size={23} color={"white"}/></div>
                  <div className={"bg-gradient-to-br from-amber-500 to-amber-600 h-full"} style={{ width: `${(1- (smoothedCalorieDeficit / 7000)) * 100}%` }}/>
                </div>
              </div>
              <div className={"flex flex-row w-full justify-center mx-3 h-[32px]"}>
                <div className={"relative flex-grow bg-gradient-to-br from-slate-700 to-black w-full rounded-xl ml-1 overflow-hidden"}>
                  <div className={"flex flex-row w-full justify-center pt-[5px]"}>
                    <CgShapeRhombus size={22} color={"white"}/>
                    <div className={"-mt-[2px] ml-1"}>
                      <span className={"font-bold font-xl text-white"}>{fuzzedVis}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              Array.from(inventory.entries()).map(([category, categoryContent]) => {
                return <div key={category} className={"grid m-3 p-3 pb-4 place-items-center rounded-2xl overflow-hidden bg-white w-[230px]"}>
                  <h1 className={"text-2xl mb-3 text-center"}>{category}</h1>
                  <div className={"flex flex-col w-full justify-center"}>
                    {
                      Array.from(categoryContent.entries()).map(([name, item]) => {
                        return <div key={name} className={"flex flex-row justify-center bg-gray-200 shadow-inner rounded-xl m-1 p-1 text-center"}>
                          <p className={"text-gray-500"}>{item.count > 1 ? `${item.count}x ` : ""}</p>
                          <p>{name}</p>
                        </div>;
                      })
                    }
                  </div>
                </div>;
              })
            }
          </div>
        </div>
    )
  } else {
    return <p>Loading...</p>
  }
}

const cal_strings = {
  goToToday: "Heute",
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ],
  days: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  shortDays: [
    "So",
    "Mo",
    "Di",
    "Mi",
    "Do",
    "Fr",
    "Sa",
  ]
};

const colormap_bottom = chroma.scale(
    [
      '#0a1628', // 00:00
      '#0a1628', // 01:00
      '#0a1628', // 02:00
      '#0a1628', // 03:00
      '#112544', // 04:00
      '#1c3f71', // 05:00
      '#c18880', // 06:00
      '#ebdb82', // 07:00
      '#93cafb', // 08:00
      '#93cafb', // 09:00
      '#93cafb', // 10:00
      '#93cafb', // 11:00
      '#93cafb', // 12:00
      '#7cbdec', // 13:00
      '#7cbdec', // 14:00
      '#7cbdec', // 15:00
      '#7cbdec', // 16:00
      '#7cbdec', // 17:00
      '#75b3e0', // 18:00
      '#5ca8e7', // 19:00
      '#ea9f37', // 20:00
      '#e28040', // 21:00
      '#1c3f71', // 22:00
      '#112544', // 23:00
      '#0a1628', // 24:00
    ]
);

const colormap_top = chroma.bezier(
    [
      '#0b1326', // 00:00
      '#0b1326', // 01:00
      '#0b1326', // 02:00
      '#0b1326', // 03:00
      '#0b1326', // 04:00
      '#1a1523', // 05:00
      '#594b81', // 06:00
      '#5c8ed5', // 07:00
      '#61a3f0', // 08:00
      '#61a3f0', // 09:00
      '#61a3f0', // 10:00
      '#61a3f0', // 11:00
      '#61a3f0', // 12:00
      '#3c83d4', // 13:00
      '#3c83d4', // 14:00
      '#3c83d4', // 15:00
      '#3c83d4', // 16:00
      '#3c83d4', // 17:00
      '#3c83d4', // 18:00
      '#3c83d4', // 19:00
      '#7d537a', // 20:00
      '#373f85', // 21:00
      '#0b1326', // 22:00
      '#0b1326', // 23:00
      '#0b1326', // 24:00
    ]
);